<template>
  <div class="ReportGoodTreatStatistics content_body"  v-loading="loading">
    <!-- 搜索 -->
    <div class="nav_header" style="padding:15px 0 0 0">
      <el-form :inline="true" size="small" :model="searchFrom" @submit.native.prevent>
        <el-form-item v-if="storeEntityList.length>1" label="消耗门店">
          <el-select v-model="searchFrom.EntityID" clearable filterable placeholder="请选择门店"
            :default-first-option="true" @change="handleSaleSearch">
            <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="searchFrom.Name" clearable @keyup.enter.native="handleSaleSearch"
            @clear="handleSaleSearch" placeholder="请输入商品名称"></el-input>
        </el-form-item>
        
        <el-form-item label="项目分类">
          <el-cascader @change="handleSaleSearch" @clear="handleSaleSearch" :options="categoryList" :show-all-levels="true" clearable filterable :props="cascaderProps_" v-model="searchFrom.CategoryID" placeholder="请选择项目分类"></el-cascader>
        </el-form-item>
        <el-form-item label="项目品牌">
          <el-select v-model="searchFrom.BrandID" clearable filterable placeholder="请选择项目品牌" :default-first-option="true" @change="handleSaleSearch" @clear="handleSaleSearch">
            <el-option v-for="item in brandList" :key="item.ID" :label="item.Name" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="下单日期">
          <el-date-picker v-model="searchFrom.QueryDate" unlink-panels type="daterange" range-separator="至"
            value-format="yyyy-MM-dd" start-placeholder="下始日期" end-placeholder="结束日期" @change="handleSaleSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="handleSaleSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button v-if="isExport" type="primary" size="small" :loading="downloadLoading"
            @click="downloadSalePayExcel">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <el-table size="small" show-summary :summary-method="getListSummaries" :data="thableData">
      <el-table-column prop="ParentCategoryName" label="一级分类"></el-table-column>
      <el-table-column prop="CategoryName" label="二级分类"></el-table-column>
      <el-table-column prop="BrandName" label="品牌"></el-table-column>
      <el-table-column prop="Name" label="项目名称"></el-table-column>
      <el-table-column prop="PassengerFlow" label="消耗客流"></el-table-column>
      <el-table-column prop="PassengerFlowHead" label="消耗人头"></el-table-column>
      <el-table-column prop="TreatQuantity" label="消耗数量"></el-table-column>
      <el-table-column prop="Amount" label="消耗本金">
        <template slot-scope='scope'>{{scope.row.Amount | NumFormat}}</template>
      </el-table-column>
      <el-table-column prop="LargessAmount" label="消耗赠金">
        <template slot-scope='scope'>{{scope.row.LargessAmount | NumFormat}}</template>
      </el-table-column>
      <el-table-column prop="TreatPrice" label="人均消耗单">
        <template slot-scope='scope'>{{scope.row.TreatPrice | NumFormat}}</template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pad_15 text_right">
      <el-pagination background v-if="paginations.total > 0" @current-change="handlePageChange"
        :current-page.sync="paginations.page" :page-size="paginations.page_size"
        :layout="paginations.layout" :total="paginations.total"></el-pagination>
    </div>
    
  </div>
</template>

<script>
import EntityAPI from "@/api/Report/Common/entity";
import API from "@/api/Report/Goods/treatStatistics.js";
import permission from "@/components/js/permission.js";
import dateTime from '@/components/js/date'
export default {
 name: 'ReportGoodTreatStatistics',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isExport = permission.permission(
        to.meta.Permission,
        "Report-Goods-TreatStatistics-Export"
      );
    });
  },
  props:{},
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      isExport:false,
      loading: false,
      downloadLoading: false,
      storeEntityList:[],
      thableData:[],
      searchFrom: {
        EntityID: "",
        GoodsTypeID: "",
        CategoryID: "",
        Name: "",
        StartTime: "",
        EndTime:'',
        QueryDate: [dateTime.formatDate.format(new Date(),"YYYY-MM-DD"),dateTime.formatDate.format(new Date(),"YYYY-MM-DD")],
      },
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      sumStatementForm:{},
      brandList:[],
      categoryList:[],
      cascaderProps: {
        checkStrictly: true ,
        value: "ID",
        label: "Name",
        children: "Child",
        emitPath: false,
        expandTrigger: "hover",
      }, // 级联选择器配置项
      cascaderProps_: { 
        // multiple: true,

        checkStrictly: true,
        label:"Name",
        value:"ID",
        children:"Child"
      },

    }
  },
   /**计算属性  */
  computed: {
  },
  /**  方法集合  */
  methods: {
    /**    */
    handleSaleSearch(){
      let that = this;
      that.paginations.page = 1;
      that.entityTreatGoodsDetailStatement_list();
    },
    /**  修改分页  */
    handlePageChange(page){
      let that = this;
      that.paginations.page = page;
      that.entityTreatGoodsDetailStatement_list();
    },
    /**  合计  */
    getListSummaries({columns}){
      let that = this;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        switch (column.property) {
          case "TreatQuantity":
          case "PassengerFlowHead":
            sums[index] = (
              <span class="font_weight_600">
                {that.sumStatementForm?that.sumStatementForm[column.property]: 0}
              </span>
            );
            break;
          default:
            sums[index] = (
              <span class="font_weight_600"></span>
            );
        }

        
      });
      return sums;
    
    },

    /**  导出  */
    async downloadSalePayExcel(){
      let that = this;
      let params = Object.assign({},that.searchFrom);
      params.StartTime = params.QueryDate?params.QueryDate[0]:"" ;
      params.EndTime = params.QueryDate?params.QueryDate[1]:"";
      params.CategoryID = params.CategoryID?params.CategoryID[1]:"";

      that.downloadLoading = true;

      let res = await API.entityTreatGoodsDetailStatement_excel(params);
      this.$message.success({ message: "正在导出", duration: "4000", });
      const link = document.createElement("a");
      let blob = new Blob([res], { type: "application/octet-stream" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.download = "门店销售消耗业绩统计.xlsx"; //下载的文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      that.downloadLoading = false;
    
    },



    //获得当前用户下的权限门店
    getstoreEntityList() {
      var that = this;
      that.loading = true;
      EntityAPI.getStoreEntityList()
        .then((res) => {
          if (res.StateCode == 200) {
            that.storeEntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /** 列表   */
    async entityTreatGoodsDetailStatement_list(){
    let that = this
      let params = Object.assign({},that.searchFrom);
      params.PageNum = that.paginations.page;
      params.StartTime = params.QueryDate?params.QueryDate[0]:"" ;
      params.EndTime = params.QueryDate?params.QueryDate[1]:"";

      if(params.CategoryID && params.CategoryID.length == 1){
        params.CategoryID = params.CategoryID[0];
      }
      else if(params.CategoryID && params.CategoryID.length == 2){
        params.CategoryID = params.CategoryID[1];
      }

      that.loading = true;
      let res = await API.entityTreatGoodsDetailStatement_list(params);
      if(res.StateCode == 200){
        that.thableData = res.Data.detail.List;
        that.paginations.total = res.Data.detail.Total;
        that.sumStatementForm = res.Data.entityTreatGoodsDetailSumStatementForm;
      }
      else{
        that.$message.error(res.Message);
      }
      that.loading = false;
    
    },
    /**    */
     getGoodsTypeID(type){
      switch (type) {
        case 10:return "产品";
        case 20:return "项目";
        case 30:return "通用次卡";
        case 40:return "时效卡";
        case 50:return "储值卡";
        case 60:return "套餐卡";
      }
    },

    
    // 品牌
    getProjectBrandList: function () {
      let that = this;
      var params = {
        Name: that.name,
        Active: that.active,
      };
      API.getProjectBrandList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.brandList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    
    // 获取项目分类
    getValidProjectCategoryValid () {
      var that = this;
      that.loading = true;
      var params = {
        Name: "",
        Active: "",
      };
      API.getValidProjectCategoryValid(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.categoryList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {
    let that = this;
    that.entityTreatGoodsDetailStatement_list();
    that.getValidProjectCategoryValid();
    that.getProjectBrandList();
    that.getstoreEntityList();

  },
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {},
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
}
</script>

<style lang="scss">

.ReportGoodTreatStatistics{

 }
</style>
